import React from 'react'
import { graphql } from 'gatsby'
import LayoutLogin from '../components/LayoutLogin.js'
import Login from '../components/Login.js'
import Logo from '../components/Logo.js'

const LoginPage = ({ data, location }) => {
  if (data.markdownRemark === null) {
    data.frontmatter = {
      title: 'Login',
      description: 'Login',
    }
  }
  return (
    <LayoutLogin currentPath={location.pathname} frontmatter={data.frontmatter}>
      <div className="c-login__container o-layout__item u-10 u-6@large u-offset-1 u-offset-3@large">
        <Logo siteTitle={data.frontmatter.title} />
        <Login path="/" />
      </div>
    </LayoutLogin>
  )
}

export const pageQuery = graphql`
  query($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        path
        title
        description
        layout
      }
    }
  }
`

export default LoginPage
