import React from 'react'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import '../styles/main.scss'

const LayoutLogin = ({ children, currentPath, frontmatter, error404 }) => {
  if (!frontmatter) {
    return null
  }
  return (
    <StaticQuery
      query={graphql`
        query {
          site {
            siteMetadata {
              title
              description
            }
          }
        }
      `}
      render={data => (
        <div className="o-app o-layout o-layout--grids">
          <Helmet
            title={frontmatter.title + ' — ' + data.site.siteMetadata.title}
            meta={[
              {
                name: 'description',
                content: data.site.siteMetadata.description,
              },
            ]}
          >
            <html lang="en" />
            <meta name="robots" content="noindex" />
          </Helmet>
          <>
            <div className="c-login o-layout__item u-12 u-vertical-center o-layout o-layout--grids">
              {children}
            </div>
          </>
        </div>
      )}
    />
  )
}

export default LayoutLogin
